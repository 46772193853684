
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ElTable } from "element-plus";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup() {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const supplier_data = ref([]);
    const consignee_data = ref([]);
    const currency_data = ref([]);
    const is_consignee_data = ref([
      { id: 1, name: "Yes" },
      { id: 2, name: "No" },
    ]);
    const branch_data = ref([]);
    const pc_data = ref([]);

    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    const consignee_branch_data = ref([]);
    const warehouse_branch_data = ref([]);
    const product_data = ref([]);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Product[]>([]);
    const productList = ref<Product[]>([]);
    const type_data = ref([]);
    const incoterms_data = ref([]);
    const payment_term_data = ref([]);
    const tableData = ref<Array<Product>>([]);
    const dialogSelectProductTableVisible = ref(false);
    const uom_data = ref<Array<UOM>>([]);
    const addPlanningIndentRef = ref<null | HTMLElement>(null);

    interface UOM {
      uom_id: number;
      uom_display_name: string;
    }
    getUOM();
    getSupplierList();
    getCurrency();
    getIncoterms();
    getPaymentTerms();
    getWarehouseList("warehouse");

    interface Product {
      stock_purchase_contract_details_id: string;
      stock_purchase_contract_no: string;
      stock_purchase_contract_date: string;
      product_name: string;
      product_qunatity: string;
      uom: string;
      product_rate: string;
      product_total: string;
    }

    // const tableData: Product[] = [
    //   {
    //     prno: "PR_1",
    //     product: "prod1",
    //     quantity: "39",
    //     uom: "kg",
    //   },
    //   {
    //     prno: "PR_2",
    //     product: "prod2",
    //     quantity: "9",
    //     uom: "kg",
    //   },
    //   {
    //     prno: "PR_3",
    //     product: "prod3",
    //     quantity: "3",
    //     uom: "kg",
    //   },
    // ];

    getAddData();
    getPurchaseRequitionType();
    getProductList();
    getTableData();
    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    const formData = ref({
      is_consignee: 2,
      supplier_select: "",
      pc_select: [],
      consignee_select: "",
      supplier_branch_select: "",
      consignee_warehouse_select: "",
      warehouse_select: "",
      date: new Date(),
    });

    // const myData = ref([
    //   {
    //     prno: "PR-1",
    //     product: "prod1",
    //     quantity: "39",
    //     uom: "kg",
    //   },
    // ]);

    const myData = ref<Array<Product>>([]);
    const rules = ref({
      supplier_select: [
        {
          required: true,
          message: "Supplier is required",
          trigger: "change",
        },
      ],
      date: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      consignee_select: [
        {
          required: true,
          message: "Consignee is required",
          trigger: "change",
        },
      ],
      supplier_branch_select: [
        {
          required: true,
          message: "Supplier Branch is required",
          trigger: "change",
        },
      ],
      consignee_warehouse_select: [
        {
          required: true,
          message: "Consignee Branch is required",
          trigger: "change",
        },
      ],
      pc_select: [
        {
          required: true,
          message: "PC NO is required",
          trigger: "change",
        },
      ],
      warehouse_select: [
        {
          required: true,
          message: "Warehouse is required",
          trigger: "change",
        },
      ],
    });

    async function getPCList() {
      let params = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_branch_select"],
        is_buyer_applicable: formData.value["is_consignee"] === 2 ? 0 : 1,
        buyer_company_id:
          formData.value["is_consignee"] === 2
            ? 0
            : formData.value["consignee_select"],
        buyer_company_branch_id:
          formData.value["is_consignee"] === 2
            ? 0
            : formData.value["consignee_warehouse_select"]
            ? formData.value["consignee_warehouse_select"]
            : 0,
        warehouse_company_branch_id:
          formData.value["is_consignee"] === 1
            ? 0
            : formData.value["warehouse_select"]
            ? formData.value["warehouse_select"]
            : 0,
      };
      await store
        .dispatch(
          ActionsFi.CUST_GET_PENDING_PURCHASE_CONTRACT_BY_CLIENT_LIST,
          params
        )
        .then(({ data }) => {
          debugger;
          pc_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      let product_list = myData.value.map((item) => {
        return {
          stock_purchase_contract_details_id:
            item.stock_purchase_contract_details_id,
        };
      });
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One PC.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_branch_select"],
        is_buyer_applicable: formData.value["is_consignee"] === 2 ? 0 : 1,
        buyer_company_id:
          formData.value["is_consignee"] === 2
            ? 0
            : formData.value["consignee_select"],
        buyer_company_branch_id:
          formData.value["is_consignee"] === 2
            ? 0
            : formData.value["consignee_warehouse_select"]
            ? formData.value["consignee_warehouse_select"]
            : 0,
        warehouse_company_branch_id:
          formData.value["is_consignee"] === 1
            ? 0
            : formData.value["warehouse_select"]
            ? formData.value["warehouse_select"]
            : 0,
        product_details: product_list,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/mm_purchase_order/create",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPlanningIndentRef.value);
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    // const setCompanyData = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());

    //   var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
    //   var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
    //   var yy = data.incorporationateDate.getFullYear();
    //   var dtt = yy + "-" + mo + "-" + dt;
    //   var cinLlpin = data.llpinn === "" ? data.cinn : data.llpinn;

    //   const db_data = {
    //     company_name: data.name,
    //     company_name_alternative: data.alternativename,
    //     company_nob: data.nob_select,
    //     company_group_type: data.group_type_select,
    //     company_industry_type: data.industry_type_select,
    //     company_business_type: data.business_type_select,
    //     company_roc: data.roc_select === "" ? 0 : data.roc_select,
    //     company_doi: dtt,
    //     company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
    //     company_registration_number:
    //       data.registrationNo === "" ? 0 : data.registrationNo,
    //     company_pan: data.pan,
    //     company_website: data.website,
    //     company_verified_yes_no: false,
    //     active: true,
    //     created_user_id: user.user_id,
    //   };

    //   console.log(db_data);

    //   await store
    //     .dispatch(Actions.CUST_ADD_COMPANY, db_data)
    //     .then(({ data }) => {
    //       console.log(data);

    //       if (data.company_id) {
    //         setTimeout(() => {
    //           loading.value = false;
    //           //
    //           Swal.fire({
    //             text: "Company has been successfully created.",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             hideModal(addCompanyModalRef.value);
    //             router.go(0);
    //           });
    //         }, 2000);
    //       } else {
    //         loading.value = false;

    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getSupplierList() {
      //trade type 1 for Supplier
      let values = { trade_type: 1 };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
          consignee_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    // async function getWarehouseList() {
    //   let values = {
    //     company_id: formData.value["supplier_select"],
    //   };
    //   await store
    //     .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
    //     .then(({ data }) => {
    //       debugger;
    //       branch_data.value = data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // }

    async function getWarehouseList(callFrom) {
      let values = {};
      if (callFrom === "supplier") {
        values = {
          company_id: formData.value["supplier_select"],
        };
      } else if (callFrom === "consignee") {
        values = {
          company_id: formData.value["consignee_select"],
        };
      } else {
        values = {
          company_id: 1,
        };
      }
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          if (callFrom === "supplier") {
            branch_data.value = data;
          } else if (callFrom === "consignee") {
            consignee_branch_data.value = data;
          } else {
            warehouse_branch_data.value = data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function addProduct() {
      // tableData.value.push({
      //   Product: "test",
      //   Quantity: "3",
      //   Rate: "4",
      //   Batch_no: "3",
      //   UOM: "kg",
      // });
      //   myData.value.push({
      //     product: "",
      //     description: "",
      //     make: "",
      //     qty: "",
      //     uom: "",
      //   });
      //   console.log("table data.." + tableData.value);
    }
    function deleteRow(row) {
      myData.value.splice(row, 1);
    }
    async function getPurchaseRequitionType() {
      await store
        .dispatch(ActionsFi.CUST_GET_REQUISITION_TYPES)
        .then(({ data }) => {
          debugger;
          type_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCurrency() {
      await store
        .dispatch(ActionsFi.CUST_GET_CURRENCY)
        .then(({ data }) => {
          debugger;
          currency_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getIncoterms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getPaymentTerms() {
      await store
        .dispatch(ActionsFi.CUST_GET_PAYMENT_TERMS_DATA)
        .then(({ data }) => {
          debugger;
          payment_term_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          debugger;
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: Product[]) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectProductTableVisible.value = false;
    };

    async function getTableData() {
      let params = {
        stock_purchase_contract_ids: formData.value["pc_select"],
      };
      debugger;
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_CONRACT_BY_ID_LIST, params)
        .then(({ data }) => {
          debugger;
          data = data.map((item) => {
            let value = {
              stock_purchase_contract_details_id:
                item.stock_purchase_contract_details_id,
              stock_purchase_contract_no: item.stock_purchase_contract_no,
              stock_purchase_contract_date: item.stock_purchase_contract_date,
              product_name: item.product_name,
              product_qunatity: item.product_qunatity + " " + item.uom,
              uom: item.uom,
              product_rate: item.product_rate,
              product_total: item.product_total,
            };

            return value;
          });
          tableData.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      // if (formData.value["type_select"] === "") {
      //   Swal.fire({
      //     text: "Please select purchase requisition type first!",
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: "Ok, got it!",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //   });
      //   return false;
      // } else {
      //   dialogSelectProductTableVisible.value = true;
      // }
      dialogSelectProductTableVisible.value = true;
    };

    async function getProductList() {
      var values = { search_term: "", company_id: 0 };

      // console.log(formData.value["type_select"]);
      // alert(formData.value["type_select"]);
      let action = Actions.CUST_GET_PRODUCT_DROPDOWN_LIST;

      await store
        .dispatch(action, values)
        .then((data) => {
          debugger;
          product_data.value = data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one user",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        productList.value = multipleSelection.value;
        myData.value = multipleSelection.value;
        dialogSelectProductTableVisible.value = false;
      }
    };
    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      addPlanningIndentRef,
      roc_list_data,
      formData,
      nob_data,
      type_data,
      rules,
      submit,
      formRef,
      loading,
      addCompanyModalRef,
      addProduct,
      supplier_data,
      consignee_data,
      is_consignee_data,
      branch_data,
      pc_data,
      myData,
      currency_data,
      incoterms_data,
      payment_term_data,
      deleteRow,
      getPurchaseRequitionType,
      getSupplierList,
      getWarehouseList,
      dialogSelectProductTableVisible,
      tableData,
      multipleTableRef,
      multipleSelection,
      consignee_branch_data,
      warehouse_branch_data,
      handleSelectionChange,
      onContinue,
      onCancle,
      getTableData,
      openDilogue,
      product_data,
      getProductList,
      getUOM,
      getPCList,
      uom_data,
    };
  },
});
